<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Liste des encarts"
          class="px-5 py-3"
        >
          <v-row>
            <v-col
              class="col-md-4 col-xs-6 padding15 mt-4"
            />
          </v-row>
          <v-card-text>
            <v-data-table
              :items="encarts"
              :headers="headers"
              data-cy="lb-encarts"
            >
              <!-- template modifier -->
              <template v-slot:top>
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ titreDialog }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <v-text-field
                              v-model="encartModifié.cle"
                              label="Cle"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <i18n-text-area
                              :texte.sync="encartModifié.texte"
                              label="Texte"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onFermer"
                      >
                        Fermer
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="onSauvegarder"
                      >
                        Sauvegarder
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <!-- template modifier -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  class="px-2 ml-1 primary"
                  min-width="0"
                  small
                  @click="onEditer(item)"
                >
                  <v-icon
                    small
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  v-confirm="{ok: dialog => onEffacer(item)}"
                  class="px-2 ml-1 warning"
                  min-width="0"
                  small
                >
                  <v-icon
                    small
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <v-btn
              class="primary"
              @click="onCréer()"
            >
              Créer un nouvel encart
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import I18nTextArea from '../composants/I18nTextArea.vue'

  export default {
    components: {
      I18nTextArea,
    },
    data: () => ({
      dialog: false,
      encarts: [],
      indexEncart: -1,
      encartModifié: {
        cle: '',
        texte: '',
      },
      tracesAffichage: [],
      listeScenarios: [],
      filtre: {},
      headers: [
        { text: 'Cle', value: 'cle' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
    }),
    computed: {
      titreDialog () {
        return this.indexEncart === -1 ? 'Nouvel encart' : 'Modifier un encart'
      },
    },
    watch: {
      dialog (val) {
        val || this.onFermer()
      },
    },
    mounted () {
      this.charger()
    },
    methods: {
      onEditer (item) {
        this.indexEncart = this.encarts.indexOf(item)
        this.encartModifié = Object.assign({}, item)
        this.dialog = true
      },
      onEffacer (item) {
        this.indexEncart = this.encarts.indexOf(item)
        restApiService.delete(`/api/encarts/${item.id}`).then((result) => {
          this.encarts.splice(this.indexEncart, 1)
          this.charger()
        })
      },
      charger () {
        restApiService.get('/api/encarts').then((result) => {
          this.encarts = result.data
        })
      },
      onCréer () {
        this.indexEncart = -1
        this.dialog = true
      },
      onFermer () {
        this.dialog = false
        this.$nextTick(() => {
          this.encartModifié = Object.assign({}, this.defaultItem)
          this.indexEncart = -1
        })
      },
      onSauvegarder () {
        if (this.indexEncart > -1) {
          restApiService.put('/api/encarts', this.encartModifié).then((result) => {
            this.charger()
            this.dialog = false
          })
        } else {
          restApiService.post('/api/encarts', this.encartModifié).then((result) => {
            this.charger()
            this.dialog = false
          })
        }
      },
    },
  }
</script>
<style scoped>
.padding15{
  padding: 15px;
}
</style>
