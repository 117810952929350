<template>
  <div>
    <v-textarea
      v-model="texteLangue"
      :label="libeleLangue"
      append-icon="mdi-translate"
      @click:append="changerLangue()"
      @input="miseAJour()"
    />
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      texte: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      fr: '',
      en: '',
      selectionLangue: 'fr',
      texteLangue: '',
    }),
    computed: {
      libeleLangue: function () {
        return this.selectionLangue === 'fr' ? this.label + ' (Fr)' : this.label + ' (En)'
      },
    },
    watch: {
      texte: {
        handler (newValue) {
          this.onChangerTexte(newValue)
        },
      },
    },
    created () {
      this.onChangerTexte(this.texte)
    },
    methods: {
      miseAJour () {
        if (this.selectionLangue === 'fr') {
          this.fr = this.texteLangue
        } else { this.en = this.texteLangue }
        const parser = new DOMParser()
        const dom = parser.parseFromString('<t><fr></fr><en></en></t>', 'application/xml')
        dom.documentElement.getElementsByTagName('fr')[0].textContent = this.fr
        dom.documentElement.getElementsByTagName('en')[0].textContent = this.en
        this.$emit('update:texte', dom.documentElement.outerHTML)
      },
      changerLangue () {
        if (this.selectionLangue === 'fr') {
          this.selectionLangue = 'en'
          this.texteLangue = this.en
        } else {
          this.selectionLangue = 'fr'
          this.texteLangue = this.fr
        }
      },
      onChangerTexte (newValue) {
        if (newValue.startsWith('<t>')) {
          const parser = new DOMParser()
          const dom = parser.parseFromString(newValue, 'application/xml')
          this.fr = dom.documentElement.getElementsByTagName('fr')[0].textContent
          this.en = dom.documentElement.getElementsByTagName('en')[0].textContent
          if (this.selectionLangue === 'fr') {
            this.texteLangue = this.fr
          } else {
            this.texteLangue = this.en
          }
        } else {
          this.fr = newValue
          this.en = newValue
          this.texteLangue = newValue
        }
      },
    },
  }

</script>
